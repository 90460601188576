import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/layouts/MDXLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Users of the legacy CodeStory app can import their documentation into a CodeStory SaaS project.`}</p>
    <h3 {...{
      "id": "set-up-a-new-project",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#set-up-a-new-project",
        "aria-label": "set up a new project permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Set up a new project`}</h3>
    <p>{`As described `}<Link to="/documentation/sign-up" mdxType="Link">{`here`}</Link>{`.`}</p>
    <p>{`Make sure you `}<Link to="/documentation/code-sources" mdxType="Link">{`add and configure your repository`}</Link>{`.`}</p>
    <p><em parentName="p">{`Your legacy CodeStory app documentation should live in your connected working copy`}</em>{`.`}</p>
    <h3 {...{
      "id": "import-your-legacy-documentation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#import-your-legacy-documentation",
        "aria-label": "import your legacy documentation permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Import your legacy documentation`}</h3>
    <p>{`From the menu, choose `}<em parentName="p">{`Project > Import Project`}</em>{`, locate your legacy documentation (living in the working copy) and import it.`}</p>
    <p>{`The notes are added and converted to the project.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      